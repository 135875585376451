﻿@import '../variables.less';

#main {
    display: flex;
    padding-top: @headerLineHeight !important;
    padding-bottom: 0 !important;

    & > main {
        flex: 1;
    }


    header {
        padding: 1.5rem 1.5rem 1rem;
    }
    
    .post header {
        padding: 2rem 3rem 1rem;
    }

    .post-list {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: auto auto;
        padding: 1.3rem 1rem 0rem;

        @media only screen and (max-width: 1280px) {
            grid-template-columns: auto;
        }
    }
}
