﻿@mainBackColor: #EEEEEC;
@mainForeColor: #2E3436;
@bodyBackColor: white;

@baseFontSize: 16px;
@baseFontSizePrint: 14px;

@mainFontFamily: Tahoma, Geneva, sans-serif;
@mainFontSize: @baseFontSize * 1;
@mainFontWeight: 400;
@bold: 700;

@mainLineHeight: @mainFontSize * 1.5;
@mainLineHeightPrint: @mainFontSize * 1;
@mainMargin: 1rem;
@headingMargin: 1.5rem;

@bodyMaxWidth: 1920px;
@bodyMinWidth: 800px;

@headerBackColor: @mainForeColor;
@headerForeColor: @mainBackColor;
@headerLineHeight: @baseFontSize * 4;

@logoFontFamily: "Courier New", Courier, monospace;
@logoFontSize: @baseFontSize * 2.5;
@logoPaddingTop: @baseFontSize * 0.625;

@menuWidth: @baseFontSize * 20;
