﻿@import '../variables.less';

footer.site {
    font-size: @baseFontSize;
    text-align: center;
    background: @mainBackColor;
    font-size: @baseFontSize * 0.75;
    padding: 1rem 0 !important;
    line-height: 1.5;

    div {
        display: inline;

        @media screen and (max-width: 640px) {
            display: block;
        }
    }
}
