﻿div.categories-container {
    display: grid;
    grid-template-columns: 1.5em auto;

    .icon {
        width: 1rem;
        height: 1rem;
        margin-top: 0.375rem;
    }

    span.categories {
        max-width: 99%;
        overflow: hidden;
    }

    a {
        margin-right: 0.5em;
        font-size: 0.75em;
        word-wrap: normal;
        line-break: strict;
        color: inherit;
        text-decoration: none;

        &:hover {
            border-bottom: 1px solid;
        }
    }
}
