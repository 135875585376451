﻿.cover-image {
    background-color: black;
    background-size: cover;
    background-position-y: bottom;
    position: relative;
    padding-top: 45%;

    img {
        position: absolute;
    }
}
