﻿@import '../../variables.less';
@maxWidth: 640px;

.post-list {
    article {
        width: 100%;
        padding-top: 56.25%; // 16:9
        position: relative;
        color: @headerForeColor;
        cursor: pointer;
        background: black;
        overflow: hidden;
        border-radius: 0.5rem;
        box-shadow: 0rem .2rem 1rem -0.2rem hsl(255 0% 0% / 80%);
        transition: box-shadow 250ms ease, transform 250ms ease;

        &:hover {
            box-shadow: 0rem 0.2rem 0.7rem 0rem hsl(255 0% 0% / 75%);
            transform: scale(1.015);
            z-index: 500;
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        @media only screen and (max-width: @maxWidth) {
            padding-top: unset;

            img {
                position: unset;
                margin-bottom: -5px;
            }
        }

        &:hover {
            a {
                border-bottom: 1px solid !important;
            }
        }
    }

    .cover-image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-top: unset;

        @media only screen and (max-width: @maxWidth) {
            position: unset;
        }
    }

    .data {
        position: absolute;
        background: rgba(0, 0, 0, 0.75);
        top: 0;
        bottom: 0;
        right: 0rem;
        width: 20rem;
        display: flex;
        flex-flow: column;
        backdrop-filter: blur(4px);

        @media only screen and (max-width: @maxWidth) {
            position: unset;
            background: unset;
            width: unset;
        }

        header {
            line-height: @mainFontSize * 1.2 * 1.2;
            padding: 1.5rem 1.5rem 1rem;
            order: 2;

            h1 {
                font-size: @mainFontSize * 1.2;

                a {
                    text-decoration: none;
                    color: inherit;
                    transition: border 250ms ease;
                    border-bottom: 0px transparent;
                }
            }

            @media only screen and (max-width: @maxWidth) {
                padding-top: 0.5rem;
            }
        }

        .excerpt {
            flex: 1;
            padding: 0 1.5rem;
            overflow: hidden;
            order: 3;

            @media only screen and (max-width: @maxWidth) {
                padding-bottom: 1rem;
            }
        }

        aside.category {
            order: 4;

            @media only screen and (max-width: @maxWidth) {
                order: 1;
            }
        }
    }
}