﻿@import '../variables.less';

aside.menu-container {
    width: @menuWidth;
    height: 100%;
    padding: 0.5rem;
    color: @headerForeColor;
    background: @headerBackColor;
    overflow-y: auto;
    transition: left 250ms ease;
    z-index: 2000;
    position: fixed;
    top: @headerLineHeight;
    left: -100%;
    height: 100%;
    backdrop-filter: blur(0.35em);
    background: linear-gradient(90deg, hsla(195, 8%, 20%, 100%) 0%, hsla(195, 8%, 20%, 90%) 20%, hsla(195, 8%, 20%, 80%) 100%);
    padding-bottom: @headerLineHeight;

    &+header #menu-bars {
        width: 64px;
        height: 64px;
        display: flex;

        .bars {
            width: 24px;
            height: 24px;
            display: block;
            transition: transform 250ms ease,
                background-color 100ms ease;
            cursor: pointer;
            margin: auto;

            .bar {
                display: block;
                width: 100%;
                background: @headerForeColor;
                height: 20%;
                transition: 0.3s ease all;
                border-radius: 50px;

                &.middle {
                    margin: 20% 0;
                }
            }
        }

        &:hover .bars .bar {
            background: fade(@headerForeColor, 80%) !important;
        }
    }

    body.menu-open & {
        left: 0;
        box-shadow: 0 1.2rem 1.2rem -0.3rem hsl(255 0% 0%);
    }

    a {
        color: @headerForeColor;
        text-decoration: none;
        line-height: 1.75em;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.25rem;
        transition: border 250ms ease;
        display: block;
        margin: 0.5rem 0;
        background: var(--color);
        border-radius: 0.25em 0 0 0.25em;
        border: 0.1em solid transparent;
        border-left-width: 0.5em;
        padding: 0 0.4em;

        &:hover {
            border-color: hsla(60, 1%, 93%, 30%);
            border-left-width: 0.5em;
        }
    }

    ul {
        list-style: none;
        padding: 0.5em 0;
        max-width: 70%;
        margin: 0 auto;
    }

    hr {
        height: 1px;
        border: none;
        width: 50%;
        background: transparent;
        margin: 0 auto;
    }

    ul.tags li {
        display: inline-block;
        margin-right: 0.25em;

        a {
            font-size: 0.75em;
            line-height: 1em;
        }
    }
}

.menu-blur {
    background: hsla(195, 7.8%, 20%, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -500;
    backdrop-filter: blur(0.5em);
    transition: opacity 500ms ease;
    opacity: 0;

    body.menu-open & {
        opacity: 100;
        z-index: 500;
    }
}
