﻿@import "../../variables.less";

.category {
    height: 3rem;
    line-height: 3rem;
    padding: 0 1.5em;
    font-size: @baseFontSize;
    color: @headerForeColor;
    position: relative;
    z-index: 0;

    .icon {
        fill: @headerForeColor;
        width: @baseFontSize;
        margin-bottom: -2px;
    }

    .background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: @headerBackColor; // #f5f5f4;
        z-index: -1;
        opacity: 0.65;
    }
}