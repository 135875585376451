﻿@import "../variables.less";
@maxWidth: 480px;

article.post {
    width: 100%;
    position: relative;
    background: @mainBackColor;

    .imageContainer {
        box-shadow: 0px 0px 32px 4px rgba(0, 0, 0, .5);
        position: relative;
        padding-top: 56.25%; // 16:9

        &>img {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .data {
        margin: -10rem auto 0;
        max-width: 960px;
        width: 80vw;
        min-width: 320px;
        z-index: 100;
        position: relative;
        backdrop-filter: blur(4px);

        @media only screen and (max-width: 1280px) {
            margin-top: -5rem;
        }

        @media only screen and (max-width: 640px) {
            margin-top: 0;
            width: 100%;
        }

        &:before {
            content: '';
            background: linear-gradient(to bottom, black 0%, transparent 256px);
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -10000;
            filter: blur(5px);
            opacity: .65;

            @media only screen and (max-width: 640px) {
                display: none;
            }
        }

        header {
            line-height: @mainFontSize * 1.2 * 1.2;
            padding: 0.55em 1rem;
            color: @headerForeColor;
            min-height: 5rem;
            position: relative;
            z-index: 0;
            overflow: hidden;
            background-color: fade(@headerBackColor, 75%);
            border-radius: 8px 8px 0 0;

            @media only screen and (max-width: 640px) {
                border-radius: unset;
            }

            h1 {
                padding-top: 0.5rem;
            }

            a {
                color: @headerForeColor;
                text-decoration: none;

                &:hover {
                    border-bottom: 1px solid;
                }
            }
        }

        .updated {
            background: @bodyBackColor;
            font-size: 0.8em;
            padding: 0.5em;

            div {
                background: @headerBackColor;
                color: @headerForeColor;
                padding: 0.5em;
            }
        }

        .excerpt {
            flex: 1;
            padding: 2rem 3rem 1.5rem;
            overflow: hidden;
            background: @bodyBackColor;
            font-size: 1.2em;
        }

        .content {
            padding: 0 3rem 2rem;
            background: @bodyBackColor;
            box-shadow: 0 0.2rem 0.5rem -0.4rem hsl(0 0% 0% / 80%);

            img[src$='#bit-thumb'] {
                display: block;
                width: 50%;
                margin: auto;
            }

            img {
                width: 75%;
                display: block;
                margin: 2rem auto;
                border-radius: 8px;
                min-width: 300px;
                box-shadow: 0rem 0rem 1rem -0.3rem hsl(0 0% 0% / 80%);
                transition: box-shadow 250ms ease, transform 250ms ease;

                &:hover {
                    box-shadow: 0rem 0rem 1rem -0.1rem hsl(0 0% 0% / 80%);
                    transform: scale(1.01);
                }

                @media only screen and (max-width: 640px) {
                    width: 100%;
                }
            }
        }

        .author {
            &::before {
                content: 'BY ';
                font-size: 0.85em;
            }
        }

        .published:before {
            content: '@ ';
            font-size: 0.85em;
            margin-left: 0.5em;
        }

        .extra {
            font-size: 0.75em;
        }
    }

    footer {
        padding: 2rem 3rem;
        background-color: @headerBackColor;
        color: @headerForeColor;
        border-radius: 0 0 0.25rem 0.25rem;
        box-shadow: 0 0.2rem 0.5rem -0.4rem hsl(0 0% 0% / 80%);
    }
}

.content {

    h1,
    h2,
    h3,
    h4,
    p {
        line-height: 1.5em;
        padding-top: 0.5em;
    }

    h2 {
        font-size: 1.32em;
    }

    &>blockquote:first-child {
        margin-top: 0;
    }

    .footnotes {
        padding-top: 1rem;
        font-size: 0.75em;

        hr {
            display: none;
        }
    }

    pre {
        width: 100%;
        overflow: auto;
        background: @headerBackColor;
        color: @headerForeColor;
        padding: 0.5em 1em;
        font-size: 0.85em;
        border-radius: 3px;

        code {
            display: inherit;
            user-select: text;
        }

        @media screen and (max-width: 1200px) {
            width: calc(80vw - 2rem);
        }

        @media screen and (max-width: 640px) {
            width: 90vw;
        }
    }
}

article.redirect,
article.error {
    .data {
        .content {
            text-align: center;
            padding: 1rem;
        }
    }
}