﻿@import '../variables.less';

header.site {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 1000;
    line-height: @headerLineHeight;
    height: @headerLineHeight;
    color: @headerForeColor;
    background: @headerBackColor;
    box-shadow: 0 .2rem 1.2rem -0.3rem hsl(255 0% 0%);

    .wrapper {
        display: flex;
        flex-flow: row;
        margin: 0 auto;
        max-width: 1920px;
    }

    .logo {
        font-size: @logoFontSize;
        font-family: @logoFontFamily;
        font-weight: @mainFontWeight;
        margin-top: @logoPaddingTop;
        padding-right: 0.5rem;
        text-align: left;
        background: inherit;
        z-index: 2000;

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    .tagline {
        flex: 1;
        font-size: @baseFontSize;
        line-height: @baseFontSize * 4.5;

        @media only screen and (max-width: 960px) {
            display: none;
        }
    }
}

body.menu-open #menu-bars .bars {
    transform: rotate(-45deg);

    .bar {
        background: fade(@headerForeColor, 60%);

        &.top,
        &.bottom {
            height: 30%;
            width: 20%;
            margin-left: 40%;
            border-radius: 50px 50px 0 0;
        }

        &.bottom {
            border-radius: 0 0 50px 50px;
        }

        &.middle {
            margin: 10% 0;
        }
    }
}